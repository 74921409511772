<template>
  <div class="vx-row">
    <vs-popup fullscreen title="Ajouter un article à la facture fournisseur" :active.sync="popupActive">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Général">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Etiquette article"  name="Etiquette" :value="Etiquette" @change.native="Etiquette=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Marque" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdMarque">
                  <vs-select-item :key="index" :value="item._id" :text="item.Libelle_marq" v-for="(item,index) in marques" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Modele"  name="Modele" :value="Modele" @change.native="Modele=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full  mt-4" type="text" label="Couleur"  name="Couleur" :value="Couleur" @change.native="Couleur=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Categorie article" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdCateg">
                  <vs-select-item :key="index" :value="item._id" :text="item.Libelle_Categ" v-for="(item,index) in categoriearticles" />
                </vs-select>

              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Style" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdStyle">
                  <vs-select-item :key="index" :value="item._id" :text="item.StyleAbreg" v-for="(item,index) in styles" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2">
                <vs-select label="Type article" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdTypeArt">
                  <vs-select-item :key="index" :value="item._id" :text="item.Type_Abreg" v-for="(item,index) in types" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2">
                <vs-input disabled class="w-full mt-4" type="text" label="Désignation" :value="designationFormatter()"  name="Designation" v-model.lazy="Designation" />
              </div>

            </div>
          </vx-card>

          <vx-card :noShadow="true" :cardBorder="true" title="Prix" class="mt-5">
            <vs-input class="w-full mt-4" type="number" label="Prix de revient"  name="PrixAchatBrut" :value="PrixAchatBrut" @change.native="PrixAchatBrut=$event.target.value" />
            <vs-input class="w-full mt-4" type="number" label="Prix Vente HT"  name="PrixVenteHT" v-model.lazy="PrixVenteHT" @change="calculTTC()" />
            <vs-input class="w-full mt-4" type="number" label="Taux TVA"  name="TauxTVA" v-model.lazy="TauxTVA" @change="calculTTC()" />
            <vs-input class="w-full mt-4" type="number" label="Prix Vente TTC"  name="PrixVenteTTC" v-model.lazy="PrixVenteTTC" @change="onPrixVenteTTCChange()" />

          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">

          <vx-card :noShadow="true" :cardBorder="true" title="Taille - Mesure">
            <!-- longeur => cote A -->
            <vs-input class="w-full mt-4" type="text" label="Cote A"  name="Longeur" :value="Longeur" @change.native="Longeur=$event.target.value" />
            <vs-input class="w-full mt-4" type="text" label="Nez"  name="Nez" :value="Nez" @change.native="Nez=$event.target.value" />
            <vs-input class="w-full mt-4" type="text" label="Longeur de branche"  name="Hauteur" :value="Hauteur" @change.native="Hauteur=$event.target.value" />
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Cote B"  name="coteb" :value="coteB" @change.native="coteB=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Unité Mesure"  name="UniteMesure" :value="UniteMesure" @change.native="UniteMesure=$event.target.value" />
              </div>
            </div>
          </vx-card>

          <vx-card :noShadow="true" :cardBorder="true" title="Infos Stock" class="mt-5">
            <vs-input class="w-full  mt-4" type="number" label="Qte"  name="Qte" :value="Qte" @change.native="Qte=$event.target.value" />
            <vs-input class="w-full  mt-4" type="number" label="Stock Minimun"  name="stockMinimun" :value="stockMinimun" @change.native="stockMinimun=$event.target.value" />
            <vs-input class="w-full  mt-4" :min="PrixAchatBrut" type="number" label="Prix Planché"  name="PrixPlanche" :value="PrixPlanche" @change.native="PrixPlanche=$event.target.value" />
          </vx-card>
          <div class="vx-row">
            <div class="vx-col w-full ml-auto mt-6">
              <vs-button class="mr-3 mb-2 w-full" @click="addNewArticle">Ajouter</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup fullscreen title="Modifier un article" :active.sync="popupUpdateActive">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Général">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Etiquette article"  name="Etiquette" :value="Etiquette" @change.native="Etiquette=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Marque" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdMarque">
                  <vs-select-item :key="index" :value="item._id" :text="item.Libelle_marq" v-for="(item,index) in marques" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Modele"  name="Modele" :value="Modele" @change.native="Modele=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full  mt-4" type="text" label="Couleur"  name="Couleur" :value="Couleur" @change.native="Couleur=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Categorie article" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdCateg">
                  <vs-select-item :key="index" :value="item._id" :text="item.Libelle_Categ" v-for="(item,index) in categoriearticles" />
                </vs-select>

              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Style" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdStyle">
                  <vs-select-item :key="index" :value="item._id" :text="item.StyleAbreg" v-for="(item,index) in styles" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2">
                <vs-select label="Type article" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdTypeArt">
                  <vs-select-item :key="index" :value="item._id" :text="item.Type_Abreg" v-for="(item,index) in types" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2">
                <vs-input disabled class="w-full mt-4" type="text" label="Désignation" :value="designationFormatter()"  name="Designation" v-model.lazy="Designation" />
              </div>

            </div>
          </vx-card>

          <vx-card :noShadow="true" :cardBorder="true" title="Prix" class="mt-5">
            <vs-input class="w-full mt-4" type="number" label="Prix de revient"  name="PrixAchatBrut" :value="PrixAchatBrut" @change.native="PrixAchatBrut=$event.target.value" />
            <vs-input class="w-full mt-4" type="number" label="Prix Vente HT"  name="PrixVenteHT" v-model.lazy="PrixVenteHT" @change="calculTTC()"/>
            <vs-input class="w-full mt-4" type="number" label="Taux TVA"  name="TauxTVA" v-model.lazy="TauxTVA" @change="calculTTC()" />
            <vs-input class="w-full mt-4" type="number" label="Prix Vente TTC"  name="PrixVenteTTC" v-model.lazy="PrixVenteTTC" @change="onPrixVenteTTCChange()" />
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">

          <vx-card :noShadow="true" :cardBorder="true" title="Taille - Mesure">
            <!-- longeur => cote A -->
            <vs-input class="w-full mt-4" type="text" label="Cote A"  name="Longeur" :value="Longeur" @change.native="Longeur=$event.target.value" />
            <vs-input class="w-full mt-4" type="text" label="Nez"  name="Nez" :value="Nez" @change.native="Nez=$event.target.value" />
            <vs-input class="w-full mt-4" type="text" label="Longeur de branche"  name="Hauteur" :value="Hauteur" @change.native="Hauteur=$event.target.value" />
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Cote B"  name="coteb" :value="coteB" @change.native="coteB=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Unité Mesure"  name="UniteMesure" :value="UniteMesure" @change.native="UniteMesure=$event.target.value" />
              </div>
            </div>
          </vx-card>

          <vx-card :noShadow="true" :cardBorder="true" title="Infos Stock" class="mt-5">
            <vs-input class="w-full  mt-4" type="number" label="Qte"  name="Qte" :value="Qte" @change.native="Qte=$event.target.value" />
            <vs-input class="w-full  mt-4" type="number" label="Stock Min"  name="stockMinimun" :value="stockMinimun" @change.native="stockMinimun=$event.target.value" />
            <vs-input class="w-full  mt-4" :min="PrixAchatBrut"  type="number" label="Prix Planché"  name="PrixPlanche" :value="PrixPlanche" @change.native="PrixPlanche=$event.target.value" />
          </vx-card>
          <div class="vx-row">
            <div class="vx-col w-full ml-auto mt-6">
              <vs-button class="mr-3 mb-2 w-full" @click="updateArticleSaveRow(curentIndex)">Modifier</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <div class="vx-col md:w-3/4">
      <vx-card>
        <!-- INVOICE METADATA -->
        <div class="vx-row leading-loose p-base">
          <div class="vx-col w-1/2 mt-base">
            <img :src="getModeleEntetePiedPageByOfficine.urlEntete" alt="groupe-logo" height="100px">
          </div>
          <div class="vx-col w-1/2 text-right">
            <h1>Bon de réception</h1>
            <div class="invoice__invoice-detail mt-6">
              <h6>BR N°.</h6>
              <p>{{ CodeCmde }}</p>

              <h6 class="mt-4">DATE</h6>
              <p>{{DateComdF | moment("calendar", "July 10 2011") }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-12">
            <h5>Officine:</h5>
            <div class="invoice__recipient-info my-4">
              <p>{{ officineName }}</p>
            </div>
          </div>
          <div class="vx-col w-1/2 mt-base text-right mt-12">
            <h5>Fournisseur:</h5>
            <div class="invoice__company-info my-4">
              <p>{{ fournisseurName }}</p>
            </div>
          </div>
        </div>

        <!-- INVOICE CONTENT -->
        <div v-if="CodeCmde != ''" class="p-base">
          <vs-table hoverFlat :data="details">
            <!-- HEADER -->
            <template slot="thead">
              <vs-th class="pointer-events-none">Etiquette</vs-th>
              <vs-th class="pointer-events-none">Marque</vs-th>
              <vs-th class="pointer-events-none">Modèle</vs-th>
              <vs-th class="pointer-events-none">Couleur</vs-th>
              <vs-th class="pointer-events-none">Taille</vs-th>
              <vs-th class="pointer-events-none">Type</vs-th>
              <vs-th class="pointer-events-none">Qte</vs-th>
              <vs-th class="pointer-events-none">PA</vs-th>
              <vs-th class="pointer-events-none"></vs-th>
              <vs-th class="pointer-events-none"></vs-th>
            </template>
            <!-- DATA -->
            <template  class="mb-5">
              <vs-tr v-for="(item, index) in details" :key="index">
                <vs-td data-label="Etiquette">
                  {{item.Etiquette}}
                </vs-td>
                <vs-td data-label="Marque">
                  {{getMarqueName(item.IdMarque)}}
                </vs-td>
                <vs-td data-label="Modele">
                  {{item.Modele}}
                </vs-td>
                <vs-td data-label="Couleur">
                  {{item.Couleur}}
                </vs-td>
                <vs-td data-label="Taille">
                  <template v-if="item.Taille[0]">{{item.Taille[0].Longeur}}</template>/<template v-if="item.Taille[0]">{{item.Taille[0].Nez}}</template>/<template v-if="item.Taille[0]">{{item.Taille[0].Hauteur}}</template>
                </vs-td>
                <vs-td data-label="Type">
                  {{ getTypeName(item.IdTypeArt)}}
                </vs-td>
                <vs-td data-label="Qte">
                  {{item.Qte}}
                </vs-td>
                <vs-td data-label="PrixAchatBrut">
                  {{item.PrixAchatBrut}}
                </vs-td>
                <vs-td>
                  <vs-button :disabled="item.StockTransfere" color="warning" type="filled" icon-pack="feather" icon="icon-edit" @click="updateArticleFormShowRow(index)"></vs-button>
                </vs-td>
                <vs-td>
                  <vs-button :disabled="item.StockTransfere"  color="danger" type="filled" icon-pack="feather" icon="icon-trash" @click="confirmDelete(index)"></vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="mt-4"><vs-button color="primary" type="filled" @click="popupActive=true">Ajouter un élément</vs-button></div>
        </div>

      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <vs-divider position="left">Infos BR</vs-divider>
        <div class="mt-4 w-full">
          <vs-input v-if="activeBodyCommande" class="w-full" @blur="UpdateCommandeFournisseurShowForm" :disabled="griseInputCommandeNumber" label="N° BR" v-model.lazy="CodeCmde"/>
        </div>
        <vs-select label="Fournisseur" :disabled="griseInputCommandeNumber" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdFour" @change="OnSelectFournisseur(IdFour)">
          <vs-select-item class="w-full" :key="index" :value="item._id" :text="item.Nom" v-for="(item,index) in fournisseurs" />
        </vs-select>
        <vs-select label="Officine" :disabled="griseInputCommandeNumber" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdOfficine" @change="onSelectOfficine(IdOfficine)">
          <vs-select-item class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
        </vs-select>
        <div class="mt-4">
          <label class="text-sm">Date</label>
          <flat-pickr :disabled="griseInputCommandeNumber" v-model.lazy="DateComdF" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" name="dateProformat" />
          <span class="text-danger text-sm"  v-show="errors.has('naissance')">{{ errors.first('naissance') }}</span>
        </div>

        <div class="mt-4 w-full">
          <vs-input-number class="" label="Remise:" v-model.lazy="RemiseCmd"/>
        </div>

        <vs-divider position="left">Détails article</vs-divider>
        <vs-select :disabled="griseInputCommandeNumber" label="Categorie article" autocomplete class="selectExample w-full select-large mt-4" v-model.lazy="IdCateg">
          <vs-select-item :key="index" :value="item._id" :text="item.Libelle_Categ" v-for="(item,index) in categoriearticles" />
        </vs-select>
        <div class="mt-4"><vs-button class="w-full" :disabled="griseInputCommandeNumber" color="primary" type="filled" @click="UpdateCommandeFournisseur">Sauvegarder</vs-button></div>

      </vx-card>
    </div>
  </div>
</template>

<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
export default {
  name: 'EditBr',
  components: {
    flatPickr
  },
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,
      IdOfficine: '',
      officineName: '',
      CodeCmde: '',
      IdFour: '',
      fournisseurName: '',
      IdUser: '',
      RemiseCmd: 0,
      DateComdF: null,
      refArcticle: '',
      IdArticleCatalogue: null,
      IdCateg: null,
      IdStyle: null,
      IdMarque: null,
      IdTypeArt: null,
      Etiquette: '',
      Modele: '',
      Longeur: '',
      Hauteur: '',
      Nez: '',
      coteB: '',
      Couleur: '',
      Designation: '',
      UniteMesure: 'U',
      PrixPlanche: 0,
      PrixAchatBrut: 0,
      Remise: 0,
      PrixNet: 0,
      PrixVenteHT: 0,
      TauxTVA: 18,
      PrixVenteTTC: 0,
      CodeBarre: 0,
      stockMinimun: 0,
      Qte: 0,
      StockTransfere: false,
      entete: ['Etiquette', 'Marque', 'Modèle', 'Couleur', 'Taille', 'Type', 'Qte', 'PA', 'Action'],
      details: [],
      popupActive: false,
      popupUpdateActive: false,
      curentIndex: null,
      griseInputCommandeNumber: false,
      transfert_total_in_stock: false,
      commandeFourId: this.$route.params.commandeFourId
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    },
    categoriearticles () {
      return this.$store.state.categorie_article.categorie_articles
    },
    styles () {
      return this.$store.state.style.styles
    },
    marques () {
      return this.$store.state.marque.marques
    },
    types () {
      return this.$store.state.type.types
    },
    activeBodyCommande () {
      if (this.IdFour === '' | this.IdOfficine === '' | this.DateComdF === null | this.IdCateg === '') {
        return false
      } else {
        return true
      }
    },
    getModeleEntetePiedPageByOfficine () {
      return this.$store.getters['entete_piedpage/getModeleEntetePiedPageByOfficine'](this.IdOfficine)
    }
  },
  methods: {
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */
    onPrixVenteTTCChange () {
      this.PrixVenteHT = Math.round(((this.PrixVenteTTC * 100) / (100 + this.TauxTVA)))
    },
    calculTTC () {
      const TVA = ((+this.PrixVenteHT * +this.TauxTVA) / 100)
      const total = (+this.PrixVenteHT + +TVA)

      this.PrixVenteTTC =  Math.round(total)
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic ? officine.NomOffic : ''
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getTypeName (id) {
      const singleType = this.$store.getters['type/getTypeById'](id)
      return singleType ? singleType.Type_Abreg : ''
    },
    designationFormatter () {
      return `${this.getMarqueName()} ${this.Modele}-${this.Couleur}`
    },
    OnSelectFournisseur (id) {
      const fournisseur = this.$store.state.fournisseur.fournisseurs.find((f) => f._id === id)
      this.fournisseurName = fournisseur.Nom ? fournisseur.Nom : ''
    },

    UpdateCommandeFournisseurShowForm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Modifier BR',
        text: 'Avez-vous terminer la modification du champ N° BR ?',
        acceptText: 'Modifier',
        cancelText: 'Annuler',
        accept: this.UpdateCommandeFournisseur
      })
    },

    UpdateCommandeFournisseur () {
      const payload = {

        IdOfficine: this.IdOfficine,
        CodeCmde: this.CodeCmde,
        IdFour: this.IdFour,
        IdUser: this.activeUserInfos._id,
        RemiseCmd: this.RemiseCmd,
        DateComdF: this.DateComdF,
        etatReglement: this.etatReglement,
        transfert_total_in_stock: this.transfert_total_in_stock,
        id: this.$route.params.commandeFourId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_fourn/updateCommandeFournisseur', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },

    addNewArticle () {
      const ligne = {
        Etiquette: this.Etiquette,
        IdCateg: this.IdCateg,
        IdStyle: this.IdStyle,
        IdMarque: this.IdMarque,
        IdTypeArt: this.IdTypeArt,
        Modele: this.Modele,
        Taille: {
          Longeur: this.Longeur,
          Hauteur: this.Hauteur,
          Nez: this.Nez
        },
        coteB: this.coteB,
        Couleur: this.Couleur,
        UniteMesure: this.UniteMesure,
        PrixPlanche: this.PrixPlanche,
        PrixAchatBrut: this.PrixAchatBrut,
        Remise: this.Remise,
        PrixNet: this.PrixNet,
        PrixVenteHT: this.PrixVenteHT,
        TauxTVA: this.TauxTVA,
        PrixVenteTTC: this.PrixVenteTTC,
        CodeBarre: this.CodeBarre,
        stockMinimun: this.stockMinimun,
        Qte: this.Qte,
        StockTransfere: false

      }

      const payload = {
        row: ligne,
        id: this.commandeFourId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_fourn/addNewLigne', payload)
        .then((resp) => {

          this.details = resp.data.details
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.Etiquette = ''

          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },

    confirmDelete (index) {
      this.curentIndex = index
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Article Supprimer',
        text: 'Vous allez supprimer la ligne',
        acceptText: 'Sauvegarder',
        cancelText: 'Annuler',
        accept: this.deleteArticleRow
      })
    },

    deleteArticleRow () {

      const payload = {
        Index: this.curentIndex,
        id: this.commandeFourId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_fourn/deleteLigne', payload)
        .then(() => {
          this.details.splice(this.curentIndex, 1)
          this.Etiquette = ''
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    updateArticleSaveRow (index) {

      const ligne = {
        Etiquette: this.Etiquette,
        IdCateg: this.IdCateg,
        IdStyle: this.IdStyle,
        IdMarque: this.IdMarque,
        IdTypeArt: this.IdTypeArt,
        Modele: this.Modele,
        Taille: {
          Longeur: this.Longeur,
          Hauteur: this.Hauteur,
          Nez: this.Nez
        },
        coteB: this.coteB,
        Couleur: this.Couleur,
        UniteMesure: this.UniteMesure,
        PrixPlanche: this.PrixPlanche,
        PrixAchatBrut: this.PrixAchatBrut,
        Remise: this.Remise,
        PrixNet: this.PrixNet,
        PrixVenteHT: this.PrixVenteHT,
        TauxTVA: this.TauxTVA,
        PrixVenteTTC: this.PrixVenteTTC,
        CodeBarre: this.CodeBarre,
        stockMinimun: this.stockMinimun,
        Qte: this.Qte

      }

      const payload = {
        row: ligne,
        Index: index,
        id: this.commandeFourId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_fourn/updateLigne', payload)
        .then(() => {

          Object.assign(this.details[index], ligne)
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.Etiquette = ''
          this.popupUpdateActive = false
          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },

    updateArticleFormShowRow (index) {
      this.curentIndex = index
      const article = this.details[index]

      this.Etiquette = article.Etiquette
      this.IdCateg = article.IdCateg
      this.IdStyle = article.IdStyle
      this.IdMarque = article.IdMarque
      this.IdTypeArt = article.IdTypeArt
      this.Modele = article.Modele
      this.Longeur = article.Taille[0] ? article.Taille[0].Longeur : ''
      this.Hauteur = article.Taille[0] ? article.Taille[0].Hauteur : ''
      this.Nez = article.Taille[0] ? article.Taille[0].Nez : ''
      this.coteB = article.coteB
      this.Couleur = article.Couleur
      this.UniteMesure = article.UniteMesure
      this.PrixPlanche = article.PrixPlanche
      this.PrixAchatBrut = article.PrixAchatBrut
      this.Remise = article.Remise
      this.PrixNet = article.PrixNet
      this.PrixVenteHT = article.PrixVenteHT
      this.TauxTVA = article.TauxTVA
      this.PrixVenteTTC = article.PrixVenteTTC
      this.CodeBarre = article.CodeBarre
      this.stockMinimun = article.stockMinimun
      this.Qte = article.Qte

      this.popupUpdateActive = true

    },

    getCommandeFournisseurById () {

      const commandeFourn = this.$store.getters['commande_fourn/getCommandeFournisseurById'](this.$route.params.commandeFourId)

      this.onSelectOfficine(commandeFourn.IdOfficine)

      this.OnSelectFournisseur(commandeFourn.IdFour)

      this.IdOfficine = commandeFourn.IdOfficine
      this.CodeCmde = commandeFourn.CodeCmde
      this.IdFour = commandeFourn.IdFour
      this.IdUser = commandeFourn.IdUser
      this.RemiseCmd = commandeFourn.RemiseCmd
      this.DateComdF = commandeFourn.DateComdF
      this.details = commandeFourn.details
      this.etatReglement = commandeFourn.etatReglement
      this.transfert_total_in_stock = commandeFourn.transfert_total_in_stock
      this.status = commandeFourn.status

      if (commandeFourn.details.length >= 1) {
        this.IdCateg = commandeFourn.details[0].IdCateg
        const articleTransferer = commandeFourn.details.find((a) => a.StockTransfere === true)

        this.griseInputCommandeNumber = !!articleTransferer.length
      }
    },


    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getCategorieArticle () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch((error) => { console.log(error) })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs')
        .then((resp) => {
          console.log(resp)

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getStyles () {
      this.$store.dispatch('style/getStyles')
        .then(() => { })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getTypes () {
      this.$store.dispatch('type/getTypes')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getAllModelEntetePiedPage () {
      this.$store.dispatch('entete_piedpage/getAllModelEntetePiedPage')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    activeUserInfo () {
      this.IdOfficine = this.activeUserInfos.IdOfficine
    }
  },
  created () {
    this.activeUserInfo()
    this.getAllModelEntetePiedPage()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.fournisseur.fournisseurs.length > 0)) this.getFournisseurs()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticle()
    if (!(this.$store.state.style.styles.length > 0)) this.getStyles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.type.types.length > 0)) this.getTypes()
    this.getCommandeFournisseurById()
  }
}
</script>

<style lang="scss">
.not-data-table{
  display: none;
}

</style>
